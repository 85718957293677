import * as React from "react"
import { useState } from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import {
  Box,
  Flex,
  Text,
  SimpleGrid,
  Button,
  Select,
  FormLabel,
} from "@chakra-ui/react"
import { Container } from "../../components/Container"
import { Heading } from "../../components/Heading"
import { Layout } from "../../layouts/default"

// Sanity helpers
import { client, getFluidProps } from "../../lib/sanity"
import { FormulationChallengeBlock } from "../../components/Blocks/FormulationChallengeBlock"
import { Helmet } from "react-helmet"
import { DynamicLink } from "../../components/DynamicLink"
import langKeys from "../../locales/lang.json"
import { useLocale } from "../../context/locale/LocaleProvider"

const truncateString = (str, num = 160) => {
  if (str.length <= num) {
    return str
  }

  return str.slice(0, num) + "..."
}

// Limit for pagination
let limit = 12

const LatestNews = ({ data, pageContext }) => {
  const { locale } = pageContext
  const { state } = useLocale()
  const stateLocale = state?.locale || locale

  const [pageCount, setPageCount] = useState(1)
  const [paginatedItems, setPaginatedItems] = useState([])
  const [hideButton, setHideButton] = useState(false)
  const [loading, setLoading] = useState(false)
  const [filterLoading, setFilterLoading] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState("all")
  const [showSSRPosts, toggleSSRPosts] = useState(true)

  const { edges } = data.allSanityArticle

  // Query for pagination
  const groq = `*[_type == 'article'] | order(publishedAt desc) | [$from...$to]{ id, title, subTitle, slug, featureImage { asset } }`

  // Get the data
  const getData = async ({ query, params }) => {
    try {
      return await client.fetch(query, params)
    } catch (err) {
      console.error("Unable to retrieve preview data", err)
    }
  }

  const callSanity = async () => {
    setLoading(true)

    const res = await getData({
      query: groq,
      params: {
        from: pageCount * limit, // start from 3, 6, 9
        to: pageCount * limit + limit,
      },
    })

    if (res && res.length > 0) {
      setPaginatedItems([...paginatedItems, ...res])
    } else {
      console.warn("No res", res)
      setHideButton(true)
    }

    setLoading(false)
  }

  // Call the page up method
  const pageUp = () => {
    callSanity()
    setPageCount(pageCount + 1)
  }

  // Perform a call up front, if no results come back then do nothing
  React.useEffect(() => {
    const checkData = async () => {
      try {
        const res = await getData({
          query: groq,
          params: {
            from: pageCount * limit, // start from 3, 6, 9
            to: pageCount * limit + limit,
          },
        })

        if (res.length === 0) {
          setHideButton(true)
        }
      } catch (err) {
        console.error("Unable to call sanity", err)
        setHideButton(true)
      }
    }

    checkData()
  }, [])

  // Handle filter selection
  const applyCategoryFilter = async () => {
    setFilterLoading(true)
    // Get the selected category and apply to the query
    const _q = `*[_type == 'article'] | order(publishedAt desc) | { id, title, subTitle, slug, featureImage { asset }, categories[]->{slug} }`

    // Get the data
    if (selectedFilter === "all") {
      const data = await getData({
        query: groq,
        params: { from: 0, to: limit },
      })

      toggleSSRPosts(false)
      setPaginatedItems(data)
      setFilterLoading(false)
    } else {
      const data = await getData({ query: _q })
      const filteredData = data.filter(item => {
        return (
          item.categories &&
          item.categories.filter(cat => cat.slug.current === selectedFilter)
            .length > 0
        )
      })

      toggleSSRPosts(false)
      setPaginatedItems(filteredData)
      setFilterLoading(false)
    }
  }

  return (
    <Layout>
      <Helmet>
        <meta name="title" content="Latest news from Micropore" />
        <meta
          name="description"
          content="The most recent articles published by Micropore Technologies together with an archive of all historic articles"
        />
      </Helmet>

      <Box height="500px" position="relative" mb={-24} bg="blue.100">
        <StaticImage
          src="../../images/latest-news.jpg"
          alt=""
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            zIndex: 1,
          }}
        />
      </Box>

      <Container px={0}>
        <Flex
          flexDir={{ base: "column", lg: "row" }}
          justifyContent={{ lg: "space-between" }}
        >
          <Box
            bg="white"
            width={{ lg: "50%" }}
            p={{ base: 4, md: 12 }}
            pb={0}
            pos="relative"
            zIndex={2}
          >
            <Heading fontSize="40px" mb={6}>
              {langKeys["latest-news"].title[stateLocale]}
            </Heading>
            <Text>{langKeys["latest-news"].subtitle[stateLocale]}</Text>
          </Box>

          <Box
            pb={{ sm: 8, lg: 0 }}
            pl={{ sm: 4, md: 12, lg: 0 }}
            pr={{ sm: 4, md: 12, lg: 12 }}
            pt={{ sm: 6, md: 0, lg: 6 }}
            pos="relative"
            top={{ lg: "96px" }}
            width="100%"
            maxWidth={{ base: "100%", lg: "640px" }}
          >
            <Flex bgColor="gray.100" p={6} as="form" alignItems="flex-end">
              <Box width={{ sm: "100%", lg: 3 / 4 }}>
                <FormLabel mb={2} fontWeight="bold" color="brand.200">
                  {langKeys["latest-news"].filter[stateLocale]}
                </FormLabel>

                <Select
                  backgroundColor="white"
                  borderRadius={0}
                  borderWidth={2}
                  borderColor="gray.300"
                  onChange={e => setSelectedFilter(e.target.value)}
                >
                  <option value="all">
                    {langKeys["latest-news"]["show-all"][stateLocale]}
                  </option>

                  {data.allSanityDocCategory.edges.map(edge => {
                    return (
                      <option key={edge.node.id} value={edge.node.slug.current}>
                        {edge.node.title[stateLocale] || edge.node.title.en}
                      </option>
                    )
                  })}
                </Select>
              </Box>

              <Box pl={4} flex="1" width={{ sm: "100%", lg: 1 / 4 }}>
                <Button
                  isLoading={filterLoading}
                  backgroundColor="brand.200"
                  onClick={applyCategoryFilter}
                  color="white"
                  _hover={{ backgroundColor: "brand.300", color: "white" }}
                >
                  {langKeys["latest-news"]["apply-filter"][stateLocale]}
                </Button>
              </Box>
            </Flex>
          </Box>
        </Flex>

        <Container bg="white" mb={20} mt={{ md: 6, lg: 16 }}>
          <SimpleGrid columns={{ base: 1, lg: 3 }} gap={16}>
            {showSSRPosts &&
              edges.length > 0 &&
              edges.map(article => {
                let subtitle = article.node.subTitle[locale]

                if (!article.node.subTitle[locale]) {
                  subtitle = article.node.subTitle.en
                }

                return (
                  <Box
                    pb={2}
                    shadow="lg"
                    maxW={{ lg: "sm" }}
                    borderWidth="1px"
                    overflow="hidden"
                    key={article.node.id}
                  >
                    <DynamicLink
                      to={`/about-us/latest-news/${article.node.slug.current}`}
                      style={{ textDecoration: "none" }}
                    >
                      {article.node.featureImage?.asset && (
                        <GatsbyImage
                          image={getFluidProps({
                            id: article.node.featureImage.asset.id,
                          })}
                          style={{ height: 200 }}
                        />
                      )}

                      <Box p="4">
                        <Heading
                          as="h3"
                          fontSize={20}
                          bg="white"
                          mt={article.node.featureImage ? "-58px" : 0}
                          position="relative"
                          padding="16px 16px 10px"
                          mb={0}
                        >
                          {article.node.title[locale] || article.node.title.en}
                        </Heading>

                        <Box px={4}>
                          <Text mb={2} fontWeight="600" color="gray.500">
                            {locale === "en"
                              ? article.node.publishedAt
                              : article.node.publishedAtFr}
                          </Text>

                          {article.node.subTitle &&
                            article.node.subTitle[locale] && (
                              <Text mb={5}>{truncateString(subtitle)}</Text>
                            )}

                          <Button bg="blue.100" _hover={{ bg: "brand.100" }}>
                            {langKeys["read-more"][stateLocale]}
                          </Button>
                        </Box>
                      </Box>
                    </DynamicLink>
                  </Box>
                )
              })}

            {paginatedItems.length > 0 &&
              paginatedItems.map(item => {
                let subtitle = item.subTitle[stateLocale] || item.subTitle.en

                return (
                  <Box
                    pb={2}
                    shadow="lg"
                    maxW={{ lg: "sm" }}
                    borderWidth="1px"
                    overflow="hidden"
                    key={item.id}
                  >
                    <Link
                      to={`/about-us/latest-news/${item.slug.current}`}
                      style={{ textDecoration: "none" }}
                    >
                      {item.featureImage?.asset && (
                        <GatsbyImage
                          image={getFluidProps({
                            id: item.featureImage.asset._ref,
                          })}
                          style={{ height: 200 }}
                        />
                      )}

                      <Box p="4">
                        <Heading
                          as="h3"
                          fontSize={20}
                          bg="white"
                          mt={item.featureImage ? "-58px" : 0}
                          position="relative"
                          padding="16px 16px 10px"
                          mb={0}
                        >
                          {item.title[stateLocale] || item.title.en}
                        </Heading>

                        <Box px={4}>
                          {subtitle && (
                            <Text mb={5}>{truncateString(subtitle)}</Text>
                          )}

                          <Button bg="blue.100" _hover={{ bg: "brand.100" }}>
                            {langKeys["read-more"][stateLocale]}
                          </Button>
                        </Box>
                      </Box>
                    </Link>
                  </Box>
                )
              })}
          </SimpleGrid>

          {!hideButton && (
            <Flex justifyContent="center" mt={16}>
              <Button
                onClick={pageUp}
                isLoading={loading}
                loadingText="Please wait..."
              >
                {langKeys["latest-news"]["load-more"][stateLocale]}
              </Button>
            </Flex>
          )}
        </Container>

        <Container
          bg="white"
          pt={4}
          borderTopWidth={2}
          borderTopColor="gray.300"
        >
          <FormulationChallengeBlock locale={locale} />
        </Container>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ArticlesQuery {
    allSanityArticle(limit: 12, sort: { fields: publishedAt, order: DESC }) {
      edges {
        node {
          id
          publishedAt(formatString: "Do MMMM YYYY")
          publishedAtFr: publishedAt(formatString: "Do MMMM YYYY", locale: "fr")
          title {
            en
            fr
            es
            it
            de
            nl
            ko
            ja
          }
          subTitle {
            en
            fr
            es
            it
            de
            nl
            ko
            ja
          }
          slug {
            current
          }
          featureImage {
            asset {
              id
            }
          }
        }
      }
    }

    allSanityDocCategory {
      edges {
        node {
          id
          title {
            en
            fr
            es
            it
            de
            nl
            ko
            ja
          }
          slug {
            current
          }
        }
      }
    }
  }
`

export default LatestNews
